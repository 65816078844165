.image-container {
    position: relative;
    /* width: 50%; */
  }
  
  .gallery-img-size {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .image-container:hover .image {
    opacity: 0.1;
  }
  
  .image-container:hover .middle {
    opacity: 1;
  }
  
  .image_title {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 16px;
    padding: 6px 32px;
  }