.post-img img{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    object-fit: cover;
}

.blog-bg{
    background-image: url("../img/blog-bg.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    /* margin-top: 100px; */
}

/* @media (max-width:1024px) {
    .post-img img{
        width: 450px;
        height: 450px;
        display: flex;
        justify-content: center;
    }
} */

    /* @media (max-width:768px) {
    .post-img img{
        width: 330px;
        height: 330px;
        display: flex;
        justify-content: center;
    }
} */

    /* @media (max-width:1024px) {
    .post-img img{
        width: 100%;
        height: 450px;
        display: flex;
        justify-content: center;
    }

} */