.fa-clock{
    display: none !important;
}

.footer-icons a{
    font-size: 20px;
    margin: 0 5px;
}

.footer-icons{
    margin: -20px 0;
}
